import React, { useState, useEffect, useRef } from "react";
import {
    TrendingUp,
    CheckCircle,
    Clock,
    Pencil,
    Plus,
    Edit,
    ChevronDown,
    ChevronUp,
    AlignLeft,
    Trash2,
    Maximize2,
    Minimize2,
} from "lucide-react";
import "flag-icons/css/flag-icons.min.css";

const countryAliases = {
    br: ["brasil", "brazil"],
    us: ["eua", "usa", "estados unidos", "united states"],
    ar: ["argentina"],
    bo: ["bolivia"],
    cl: ["chile"],
    co: ["colombia"],
    cr: ["costa rica"],
    cu: ["cuba"],
    do: ["republica dominicana", "dominican republic"],
    ec: ["ecuador"],
    sv: ["el salvador"],
    gt: ["guatemala"],
    ht: ["haiti"],
    hn: ["honduras"],
    mx: ["mexico", "méxico"],
    ni: ["nicaragua"],
    pa: ["panama", "panamá"],
    py: ["paraguay"],
    pe: ["peru", "perú"],
    uy: ["uruguay"],
    ve: ["venezuela"],
    de: ["germany", "alemanha"],
    fr: ["france", "frança"],
    gb: ["united kingdom", "reino unido"],
    it: ["italy", "italia", "itália"],
    es: ["spain", "espanha"],
    pl: ["poland", "polonia", "polônia"],
    ro: ["romania", "romênia"],
    mt: ["malta"],
    nl: ["netherlands", "holanda", "países baixos"],
    be: ["belgium", "bélgica"],
    gr: ["greece", "grécia"],
    cz: ["czech republic", "república tcheca"],
    pt: ["portugal"],
    se: ["sweden", "suécia"],
    hu: ["hungary", "hungria"],
    at: ["austria", "áustria"],
    ch: ["switzerland", "suíça"],
    dk: ["denmark", "dinamarca"],
    fi: ["finland", "finlândia"],
    sk: ["slovakia", "eslováquia"],
    no: ["norway", "noruega"],
};

const normalizeCountryName = (s) => {
    if (!s) return "xx";
    const l = s
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    return (
        Object.entries(countryAliases).find(([, aliases]) =>
            aliases.includes(l),
        )?.[0] || "xx"
    );
};

const latLngToPercentCoords = (lat, lng) => ({
    x: ((lng + 180) / 360) * 100,
    y: 100 - ((lat + 90) / 180) * 100,
});

const getYearFromLabel = (l) =>
    parseInt(l?.match(/No ano de\s+(\d+)/i)?.[1] || 0, 10);

const getMonthFromLabel = (l) =>
    parseInt(l?.match(/mês\s+(\d+)/i)?.[1] || 0, 10);

const groupMilestones = (list) => {
    const g = {};
    list.forEach((m) => {
        if (!m?.label) return;
        const y = getYearFromLabel(m.label);
        const mo = getMonthFromLabel(m.label);
        const k = `${y}-${mo}`;
        if (!g[k]) g[k] = { ...m, items: [m], completed: m.completed };
        else {
            g[k].items.push(m);
            g[k].completed = g[k].completed && m.completed;
        }
    });
    return Object.values(g).sort(
        (a, b) =>
            getYearFromLabel(a.label) - getYearFromLabel(b.label) ||
            getMonthFromLabel(a.label) - getMonthFromLabel(b.label),
    );
};

const CurrentLocationPin = ({ coords }) => (
    <div
        className="absolute animate-bounce"
        style={{
            left: `${coords?.x ?? 38}%`,
            top: `${(coords?.y ?? 51) - 3}%`,
        }}
    >
        <div className="relative">
            <div className="w-4 h-4 bg-green-500 rounded-full shadow-lg ring-4 ring-green-200" />
            <div className="absolute w-4 h-4 bg-green-500 rounded-full shadow-lg animate-ping" />
        </div>
    </div>
);

const TimelineModal = ({
    milestones,
    countries,
    onClose,
    position,
    onDrag,
    onAddMilestoneFromTimeline,
}) => {
    const [expandedYears, setExpandedYears] = useState({});
    const [expandedCountries, setExpandedCountries] = useState({});
    const [isDragging, setIsDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

    // Estados para adicionar novo objetivo direto pela linha do tempo
    const [isAddingTimelineMilestone, setIsAddingTimelineMilestone] =
        useState(false);
    const [newTimelineMilestoneTitle, setNewTimelineMilestoneTitle] =
        useState("");
    const [newTimelineMilestoneYear, setNewTimelineMilestoneYear] =
        useState("");
    const [newTimelineMilestoneMonth, setNewTimelineMilestoneMonth] =
        useState("");
    const [newTimelineMilestoneCountry, setNewTimelineMilestoneCountry] =
        useState("");

    const timelineData = React.useMemo(() => {
        const data = {};
        milestones.forEach((m) => {
            const year = m.year || getYearFromLabel(m.label);
            const countryId = m.id.split("_")[3] || "";
            const countryObj = countries.find(
                (c) => c.id.toLowerCase() === countryId.toLowerCase(),
            ) || { label: countryId, name: countryId, id: countryId };
            data[year] = data[year] || {};
            data[year][countryId] = data[year][countryId] || {
                country: countryObj,
                milestones: [],
            };
            data[year][countryId].milestones.push(m);
        });
        return data;
    }, [milestones, countries]);

    const handleMouseDown = (e) => {
        if (
            e.target.closest("button") ||
            e.target.closest(".overflow-y-auto") ||
            e.target.closest("input") ||
            e.target.closest("select")
        ) {
            return;
        }
        setIsDragging(true);
        setDragStart({ x: e.clientX - position.x, y: e.clientY - position.y });
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        onDrag({ x: e.clientX - dragStart.x, y: e.clientY - dragStart.y });
    };

    const handleMouseUp = () => setIsDragging(false);

    useEffect(() => {
        if (isDragging) {
            document.addEventListener("mousemove", handleMouseMove);
            document.addEventListener("mouseup", handleMouseUp);
        }
        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isDragging]);

    const toggleKey = (key, setFn) =>
        setFn((prev) => ({ ...prev, [key]: !prev[key] }));

    const months = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ];

    const handleSaveNewFromTimeline = () => {
        if (
            !newTimelineMilestoneTitle ||
            !newTimelineMilestoneYear ||
            !newTimelineMilestoneMonth ||
            !newTimelineMilestoneCountry
        ) {
            return alert("Preencha todos os campos.");
        }
        onAddMilestoneFromTimeline(
            newTimelineMilestoneCountry,
            +newTimelineMilestoneYear,
            +newTimelineMilestoneMonth,
            newTimelineMilestoneTitle,
        );
        setNewTimelineMilestoneTitle("");
        setNewTimelineMilestoneYear("");
        setNewTimelineMilestoneMonth("");
        setNewTimelineMilestoneCountry("");
        setIsAddingTimelineMilestone(false);
    };

    return (
        <div
            className="absolute w-96 max-h-[70vh] bg-gray-800 rounded-xl shadow-xl border-2 border-amber-700 flex flex-col z-40"
            style={{
                left: `${position.x}px`,
                top: `${position.y}px`,
                cursor: isDragging ? "grabbing" : "grab",
            }}
            onMouseDown={handleMouseDown}
        >
            <div className="p-4 border-b border-gray-700 flex justify-between items-center">
                <h3 className="text-lg font-bold text-amber-300">
                    Linha do Tempo
                </h3>
                <button
                    onClick={onClose}
                    className="text-amber-400 hover:text-amber-200"
                >
                    ×
                </button>
            </div>

            {/* Botão para abrir formulário de novo objetivo pela linha do tempo */}
            <div className="p-4 pt-2 border-b border-gray-700">
                <button
                    onClick={() => setIsAddingTimelineMilestone(true)}
                    className="flex items-center gap-2 bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-full"
                >
                    <Plus className="w-4 h-4" />
                    Adicionar Novo Objetivo
                </button>
                {isAddingTimelineMilestone && (
                    <div className="mt-4 bg-gray-700 p-3 rounded">
                        <label className="block text-sm text-amber-100 mb-1">
                            País
                        </label>
                        <select
                            value={newTimelineMilestoneCountry}
                            onChange={(e) =>
                                setNewTimelineMilestoneCountry(e.target.value)
                            }
                            className="w-full p-2 rounded bg-gray-600 text-amber-100 mb-2"
                        >
                            <option value="">Selecione um país</option>
                            {countries.map((c) => (
                                <option key={c.id} value={c.id}>
                                    {c.label || c.name || c.id}
                                </option>
                            ))}
                        </select>

                        <label className="block text-sm text-amber-100 mb-1">
                            Título
                        </label>
                        <input
                            type="text"
                            className="w-full p-2 rounded bg-gray-600 text-amber-100 mb-2"
                            value={newTimelineMilestoneTitle}
                            onChange={(e) =>
                                setNewTimelineMilestoneTitle(e.target.value)
                            }
                        />

                        <label className="block text-sm text-amber-100 mb-1">
                            Ano
                        </label>
                        <input
                            type="number"
                            className="w-full p-2 rounded bg-gray-600 text-amber-100 mb-2"
                            value={newTimelineMilestoneYear}
                            onChange={(e) =>
                                setNewTimelineMilestoneYear(e.target.value)
                            }
                        />

                        <label className="block text-sm text-amber-100 mb-1">
                            Mês
                        </label>
                        <input
                            type="number"
                            className="w-full p-2 rounded bg-gray-600 text-amber-100 mb-2"
                            value={newTimelineMilestoneMonth}
                            onChange={(e) =>
                                setNewTimelineMilestoneMonth(e.target.value)
                            }
                        />

                        <div className="flex justify-end gap-2 mt-2">
                            <button
                                onClick={() =>
                                    setIsAddingTimelineMilestone(false)
                                }
                                className="px-3 py-1 bg-gray-500 text-white rounded hover:bg-gray-600"
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={handleSaveNewFromTimeline}
                                className="px-3 py-1 bg-green-600 text-white rounded hover:bg-green-700"
                            >
                                Salvar
                            </button>
                        </div>
                    </div>
                )}
            </div>

            <div className="overflow-y-auto flex-1 p-4">
                {Object.entries(timelineData)
                    .sort(([a], [b]) => parseInt(a) - parseInt(b))
                    .map(([year, countriesData]) => (
                        <div key={year} className="mb-4">
                            <button
                                onClick={() =>
                                    toggleKey(year, setExpandedYears)
                                }
                                className="w-full flex items-center justify-between p-2 bg-gray-700 rounded-lg text-amber-300 hover:bg-gray-600"
                            >
                                <span className="font-bold">{year}</span>
                                {expandedYears[year] ? (
                                    <ChevronUp className="w-4 h-4" />
                                ) : (
                                    <ChevronDown className="w-4 h-4" />
                                )}
                            </button>

                            {expandedYears[year] && (
                                <div className="ml-4 mt-2 space-y-2">
                                    {Object.entries(countriesData).map(
                                        ([
                                            countryId,
                                            { country, milestones },
                                        ]) => {
                                            const iso = normalizeCountryName(
                                                country.id || country.label,
                                            );
                                            const k = `${year}-${countryId}`;
                                            return (
                                                <div
                                                    key={countryId}
                                                    className="border-l-2 border-gray-700 pl-4"
                                                >
                                                    <button
                                                        onClick={() =>
                                                            toggleKey(
                                                                k,
                                                                setExpandedCountries,
                                                            )
                                                        }
                                                        className="w-full flex items-center justify-between p-2 bg-gray-700/50 rounded-lg text-amber-200 hover:bg-gray-600"
                                                    >
                                                        <div className="flex items-center gap-2">
                                                            <span
                                                                className={`fi fi-${iso}`}
                                                            />
                                                            <span className="capitalize">
                                                                {country.label ||
                                                                    country.name}
                                                            </span>
                                                        </div>
                                                        {expandedCountries[
                                                            k
                                                        ] ? (
                                                            <ChevronUp className="w-4 h-4" />
                                                        ) : (
                                                            <ChevronDown className="w-4 h-4" />
                                                        )}
                                                    </button>
                                                    {expandedCountries[k] && (
                                                        <div className="ml-4 mt-2 space-y-2">
                                                            {milestones
                                                                .sort(
                                                                    (a, b) =>
                                                                        a.month -
                                                                        b.month,
                                                                )
                                                                .map(
                                                                    (
                                                                        milestone,
                                                                    ) => {
                                                                        const desc =
                                                                            milestone.label
                                                                                ?.split(
                                                                                    ":",
                                                                                )[1]
                                                                                ?.trim() ||
                                                                            "";
                                                                        return (
                                                                            <div
                                                                                key={
                                                                                    milestone.id
                                                                                }
                                                                                className="flex flex-col gap-1 p-3 bg-gray-700/30 rounded-lg"
                                                                            >
                                                                                <div className="flex items-center gap-2">
                                                                                    <div
                                                                                        className={`p-1 rounded ${
                                                                                            milestone.completed
                                                                                                ? "bg-green-700/30"
                                                                                                : "bg-amber-700/30"
                                                                                        }`}
                                                                                    >
                                                                                        {milestone.completed ? (
                                                                                            <CheckCircle className="w-4 h-4 text-green-400" />
                                                                                        ) : (
                                                                                            <Clock className="w-4 h-4 text-amber-400" />
                                                                                        )}
                                                                                    </div>
                                                                                    <span className="text-sm font-medium text-amber-200">
                                                                                        {months[
                                                                                            milestone.month -
                                                                                                1
                                                                                        ] ||
                                                                                            milestone.month}
                                                                                    </span>
                                                                                </div>
                                                                                <span className="text-sm text-gray-300 ml-7">
                                                                                    {
                                                                                        desc
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                        );
                                                                    },
                                                                )}
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        },
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default function MagicMap() {
    const [countries, setCountries] = useState([]);
    const [milestones, setMilestones] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [currentCountry, setCurrentCountry] = useState(null);
    const [currentCoords, setCurrentCoords] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [dragItemId, setDragItemId] = useState(null);
    const [dragItemType, setDragItemType] = useState(null);
    const [isTimelineVisible, setIsTimelineVisible] = useState(false);
    const [timelinePosition, setTimelinePosition] = useState({
        x: 100,
        y: 100,
    });
    const [isAddingMilestone, setIsAddingMilestone] = useState(false);
    const [newMilestoneTitle, setNewMilestoneTitle] = useState("");
    const [newMilestoneYear, setNewMilestoneYear] = useState("");
    const [newMilestoneMonth, setNewMilestoneMonth] = useState("");
    const [togglingIds, setTogglingIds] = useState([]);
    const [editingMilestoneId, setEditingMilestoneId] = useState(null);
    const [editedMilestoneTitle, setEditedMilestoneTitle] = useState("");
    const [editedMilestoneYear, setEditedMilestoneYear] = useState("");
    const [deletingItem, setDeletingItem] = useState(null);
    const containerRef = useRef(null);

    const [isFullscreen, setIsFullscreen] = useState(false);

    const toggleFullscreen = () => {
        if (!isFullscreen) {
            if (containerRef.current.requestFullscreen) {
                containerRef.current.requestFullscreen();
            } else if (containerRef.current.webkitRequestFullscreen) {
                containerRef.current.webkitRequestFullscreen();
            } else if (containerRef.current.msRequestFullscreen) {
                containerRef.current.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(!!document.fullscreenElement);
        };

        document.addEventListener("fullscreenchange", handleFullscreenChange);
        document.addEventListener(
            "webkitfullscreenchange",
            handleFullscreenChange,
        );
        document.addEventListener(
            "mozfullscreenchange",
            handleFullscreenChange,
        );
        document.addEventListener("MSFullscreenChange", handleFullscreenChange);

        return () => {
            document.removeEventListener(
                "fullscreenchange",
                handleFullscreenChange,
            );
            document.removeEventListener(
                "webkitfullscreenchange",
                handleFullscreenChange,
            );
            document.removeEventListener(
                "mozfullscreenchange",
                handleFullscreenChange,
            );
            document.removeEventListener(
                "MSFullscreenChange",
                handleFullscreenChange,
            );
        };
    }, []);

    useEffect(() => {
        fetch("https://organifyhub.com:5003/api/my-life-map")
            .then((r) => r.json())
            .then((d) => {
                const p = (n) =>
                    n.lat && n.lng
                        ? { ...n, ...latLngToPercentCoords(n.lat, n.lng) }
                        : n;
                const c = d.nodes.filter((n) => n.type === "country").map(p);
                const m = d.nodes.filter((n) => n.type === "milestone").map(p);
                const curr = c.find((cc) => cc.id === d.currentCountry);
                if (curr) {
                    setCurrentCountry(curr);
                    setCurrentCoords({ x: curr.x, y: curr.y });
                }
                setCountries(c);
                setMilestones(m);
            })
            .catch((e) => console.error("Erro ao buscar mapa de vida:", e));
    }, []);

    const handleCountryClick = (c) => {
        if (selectedCountry?.id === c.id) setSelectedCountry(null);
        else {
            setSelectedCountry(c);
            setSelectedLocation(null);
        }
    };

    const getCountryMilestones = (c, m = milestones) => {
        if (!c) return [];
        return groupMilestones(
            m.filter(
                (i) =>
                    i?.label && i.id.toLowerCase().includes(c.id.toLowerCase()),
            ),
        );
    };

    const handleDeleteMilestone = (item) => setDeletingItem(item);
    const confirmDelete = () => {
        if (!deletingItem) return;
        fetch("https://organifyhub.com:5003/api/delete-milestone", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ milestoneId: deletingItem.id }),
        })
            .then((r) => r.json())
            .then((d) => {
                if (d.error) alert(d.error);
                else {
                    setMilestones((old) =>
                        old.filter((m) => m.id !== deletingItem.id),
                    );
                    setSelectedLocation(null);
                    setDeletingItem(null);
                }
            })
            .catch(() => alert("Erro ao deletar objetivo!"));
    };

    const renderCountryRoutes = () => {
        const routes = [];
        countries.forEach((country, index) => {
            if (index < countries.length - 1) {
                const nextCountry = countries[index + 1];
                routes.push(
                    <line
                        key={`country-route-${country.id}-${nextCountry.id}`}
                        x1={`${country.x}%`}
                        y1={`${country.y}%`}
                        x2={`${nextCountry.x}%`}
                        y2={`${nextCountry.y}%`}
                        stroke="#4B5563"
                        strokeWidth="2"
                        strokeDasharray="8,8"
                        opacity="0.5"
                    />,
                );
            }
        });
        return routes;
    };

    const renderRoute = (o) => {
        const routes = [];
        o.forEach((obj, i) => {
            if (!obj.label || i === o.length - 1) return;
            const nxt = o[i + 1];
            if (!nxt?.label) return;
            routes.push(
                <line
                    key={`${obj.id}-${nxt.id}`}
                    x1={`${obj.x}%`}
                    y1={`${obj.y}%`}
                    x2={`${nxt.x}%`}
                    y2={`${nxt.y}%`}
                    stroke={obj.completed ? "green" : "#6B7280"}
                    strokeWidth="3"
                    strokeDasharray="5,5"
                />,
            );
        });
        return routes;
    };

    const handleMouseDown = (e, id, t) => {
        if (!isEditing) return;
        e.stopPropagation();
        setDragItemId(id);
        setDragItemType(t);
        setIsDragging(true);
    };

    const handleMouseMove = (e) => {
        if (!isDragging || !containerRef.current) return;
        const rect = containerRef.current.getBoundingClientRect();
        const xp = ((e.clientX - rect.left) / rect.width) * 100;
        const yp = ((e.clientY - rect.top) / rect.height) * 100;
        if (dragItemType === "country") {
            setCountries((p) =>
                p.map((c) =>
                    c.id === dragItemId ? { ...c, x: xp, y: yp } : c,
                ),
            );
        } else {
            setMilestones((p) =>
                p.map((m) =>
                    m.id === dragItemId ? { ...m, x: xp, y: yp } : m,
                ),
            );
        }
    };

    const handleMouseUp = () => {
        if (!isDragging) return;
        const items = dragItemType === "country" ? countries : milestones;
        const item = items.find((i) => i.id === dragItemId);
        if (item) {
            fetch("https://organifyhub.com:5003/api/update-lat-lng", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    id: dragItemId,
                    type: dragItemType,
                    x: item.x,
                    y: item.y,
                }),
            }).catch((e) => console.error("Erro ao atualizar:", e));
        }
        setDragItemId(null);
        setDragItemType(null);
        setIsDragging(false);
    };

    const handleAddMilestoneClick = () => {
        setNewMilestoneTitle("");
        setNewMilestoneYear("");
        setNewMilestoneMonth("");
        setIsAddingMilestone(true);
    };

    const handleSaveNewMilestone = () => {
        if (!newMilestoneTitle || !newMilestoneYear || !newMilestoneMonth)
            return alert("Preencha todos os campos.");
        fetch("https://organifyhub.com:5003/api/add-milestone", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                country: selectedCountry.id,
                title: newMilestoneTitle,
                year: +newMilestoneYear,
                month: +newMilestoneMonth,
            }),
        })
            .then((r) => r.json())
            .then((d) => {
                if (d.error) alert(d.error);
                else {
                    setMilestones((v) => [...v, d.newMilestone]);
                    setIsAddingMilestone(false);
                }
            })
            .catch(() => alert("Erro ao adicionar."));
    };

    // Função para adicionar milestone pela linha do tempo
    const handleAddMilestoneFromTimeline = (countryId, year, month, title) => {
        fetch("https://organifyhub.com:5003/api/add-milestone", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                country: countryId,
                title,
                year,
                month,
            }),
        })
            .then((r) => r.json())
            .then((d) => {
                if (d.error) alert(d.error);
                else {
                    setMilestones((prev) => [...prev, d.newMilestone]);
                }
            })
            .catch(() => alert("Erro ao adicionar."));
    };

    const handleToggleCheckbox = (item) => {
        const mid = item.id;
        if (togglingIds.includes(mid)) return;
        const ov = item.completed;
        const nv = !ov;
        setTogglingIds((v) => [...v, mid]);
        setMilestones((prev) => {
            const updated = prev.map((m) =>
                m.id === mid ? { ...m, completed: nv } : m,
            );
            if (selectedLocation) {
                const y = getYearFromLabel(selectedLocation.label);
                const mo = getMonthFromLabel(selectedLocation.label);
                const g = getCountryMilestones(selectedCountry, updated).find(
                    (f) =>
                        getYearFromLabel(f.label) === y &&
                        getMonthFromLabel(f.label) === mo,
                );
                if (g) setSelectedLocation(g);
            }
            return updated;
        });
        fetch("https://organifyhub.com:5003/api/toggle-milestone-complete", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ milestoneId: mid, completed: nv }),
        })
            .then((r) => r.json())
            .then((d) => {
                setTogglingIds((v) => v.filter((x) => x !== mid));
                if (d.error) {
                    alert("Erro ao atualizar no servidor.");
                    setMilestones((prev) => {
                        const rev = prev.map((m) =>
                            m.id === mid ? { ...m, completed: ov } : m,
                        );
                        if (selectedLocation) {
                            const y = getYearFromLabel(selectedLocation.label);
                            const mo = getMonthFromLabel(
                                selectedLocation.label,
                            );
                            const g = getCountryMilestones(
                                selectedCountry,
                                rev,
                            ).find(
                                (f) =>
                                    getYearFromLabel(f.label) === y &&
                                    getMonthFromLabel(f.label) === mo,
                            );
                            if (g) setSelectedLocation(g);
                        }
                        return rev;
                    });
                }
            })
            .catch(() => {
                setTogglingIds((v) => v.filter((x) => x !== mid));
                alert("Ocorreu um erro ao atualizar.");
                setMilestones((prev) => {
                    const rev = prev.map((m) =>
                        m.id === mid ? { ...m, completed: ov } : m,
                    );
                    if (selectedLocation) {
                        const y = getYearFromLabel(selectedLocation.label);
                        const mo = getMonthFromLabel(selectedLocation.label);
                        const g = getCountryMilestones(
                            selectedCountry,
                            rev,
                        ).find(
                            (f) =>
                                getYearFromLabel(f.label) === y &&
                                getMonthFromLabel(f.label) === mo,
                        );
                        if (g) setSelectedLocation(g);
                    }
                    return rev;
                });
            });
    };

    const startEditingMilestone = (m) => {
        setEditingMilestoneId(m.id);
        setEditedMilestoneTitle(m.label?.split(":")[1]?.trim() || "");
        setEditedMilestoneYear(m.year || getYearFromLabel(m.label));
    };

    const handleSaveEditingMilestone = (item) => {
        if (!editedMilestoneTitle || !editedMilestoneYear)
            return alert("Preencha título e ano.");
        fetch("https://organifyhub.com:5003/api/update-milestone", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                milestoneId: item.id,
                title: editedMilestoneTitle,
                year: editedMilestoneYear,
            }),
        })
            .then((r) => r.json())
            .then((d) => {
                if (d.error) alert(d.error);
                else {
                    setMilestones((old) =>
                        old.map((m) =>
                            m.id === item.id
                                ? {
                                      ...m,
                                      year: editedMilestoneYear,
                                      label: `No ano de ${editedMilestoneYear}, mês ${getMonthFromLabel(
                                          m.label,
                                      )}: ${editedMilestoneTitle}`,
                                  }
                                : m,
                        ),
                    );
                    if (selectedLocation) {
                        setSelectedLocation((oldLoc) => {
                            if (!oldLoc?.items) return oldLoc;
                            const upd = {
                                ...item,
                                year: editedMilestoneYear,
                                label: `No ano de ${editedMilestoneYear}, mês ${getMonthFromLabel(
                                    item.label,
                                )}: ${editedMilestoneTitle}`,
                            };
                            return {
                                ...oldLoc,
                                items: oldLoc.items.map((mi) =>
                                    mi.id === item.id ? upd : mi,
                                ),
                            };
                        });
                    }
                    setEditingMilestoneId(null);
                }
            })
            .catch(() => alert("Erro ao editar milestone!"));
    };

    return (
        <div className="w-full h-screen bg-gray-900 p-6 relative">
            <div
                ref={containerRef}
                className="relative w-full h-full border-4 border-amber-700 bg-gray-800 rounded-lg overflow-hidden"
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
            >
                <div
                    className="absolute inset-0"
                    style={{
                        backgroundImage:
                            "linear-gradient(#4b5563 1px,transparent 1px),linear-gradient(90deg,#4b5563 1px,transparent 1px)",
                        backgroundSize: "4% 4%",
                        opacity: "0.1",
                    }}
                />
                <svg className="absolute inset-0 w-full h-full">
                    {!selectedCountry && renderCountryRoutes()}
                    {selectedCountry &&
                        renderRoute(getCountryMilestones(selectedCountry))}
                </svg>

                {!selectedCountry && currentCoords && (
                    <CurrentLocationPin coords={currentCoords} />
                )}

                {isTimelineVisible && (
                    <TimelineModal
                        milestones={milestones}
                        countries={countries}
                        onClose={() => setIsTimelineVisible(false)}
                        position={timelinePosition}
                        onDrag={setTimelinePosition}
                        onAddMilestoneFromTimeline={
                            handleAddMilestoneFromTimeline
                        }
                    />
                )}

                {!selectedCountry ? (
                    <>
                        <div className="absolute top-4 right-4 flex flex-col sm:flex-row gap-2 items-end sm:items-center">
                            <button
                                onClick={toggleFullscreen}
                                className="flex items-center gap-2 bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-full whitespace-nowrap"
                            >
                                {isFullscreen ? (
                                    <>
                                        <Minimize2 className="w-4 h-4" />
                                        Sair da Tela Cheia
                                    </>
                                ) : (
                                    <>
                                        <Maximize2 className="w-4 h-4" />
                                        Tela Cheia
                                    </>
                                )}
                            </button>
                            <button
                                onClick={() => setIsTimelineVisible(true)}
                                className="flex items-center gap-2 bg-amber-600 hover:bg-amber-700 text-white px-4 py-2 rounded-full whitespace-nowrap"
                            >
                                <AlignLeft className="w-4 h-4" />
                                Ver Linha do Tempo
                            </button>
                        </div>

                        {!selectedCountry && currentCoords && (
                            <CurrentLocationPin coords={currentCoords} />
                        )}

                        {countries.map((ct) => {
                            const nm = ct.label || ct.name || ct.id;
                            const iso = normalizeCountryName(nm);
                            const isc = ct.id === currentCountry?.id;
                            return (
                                <div
                                    key={ct.id}
                                    onMouseDown={(e) =>
                                        handleMouseDown(e, ct.id, "country")
                                    }
                                    className="absolute cursor-pointer -translate-x-1/2 -translate-y-1/2 hover:scale-110 transition-transform duration-300"
                                    style={{
                                        left: `${ct.x ?? 50}%`,
                                        top: `${ct.y ?? 50}%`,
                                    }}
                                    onClick={() =>
                                        !isEditing && handleCountryClick(ct)
                                    }
                                >
                                    <div className="relative">
                                        <div
                                            className={`p-3 rounded-full bg-gray-700 shadow-lg ring-2 ${
                                                isc
                                                    ? "ring-green-500 ring-4"
                                                    : iso === "br"
                                                      ? "ring-green-500"
                                                      : iso === "us"
                                                        ? "ring-blue-500"
                                                        : "ring-amber-500"
                                            }`}
                                        >
                                            <span
                                                className={`fi fi-${iso} text-2xl`}
                                            />
                                        </div>
                                        <div className="absolute -bottom-8 left-1/2 -translate-x-1/2 whitespace-nowrap">
                                            <span
                                                className={`text-sm font-bold px-3 py-1 rounded-full shadow ${
                                                    isc
                                                        ? "bg-green-700 text-green-100"
                                                        : iso === "br"
                                                          ? "bg-green-700 text-green-100"
                                                          : iso === "us"
                                                            ? "bg-blue-700 text-blue-100"
                                                            : "bg-amber-700 text-amber-100"
                                                }`}
                                            >
                                                {nm}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </>
                ) : (
                    <>
                        <button
                            onClick={() => setSelectedCountry(null)}
                            className="absolute top-4 left-4 px-4 py-2 bg-amber-600 text-white rounded-lg flex items-center gap-2 hover:bg-amber-700"
                        >
                            ← Voltar para Países
                        </button>
                        <button
                            onClick={() => setIsEditing(!isEditing)}
                            className="absolute top-4 left-52 flex items-center gap-2 bg-amber-600 hover:bg-amber-700 text-white px-4 py-2 rounded-full"
                        >
                            <Pencil className="w-4 h-4" />
                            {isEditing ? "Finalizar Edição" : "Editar Mapa"}
                        </button>
                        <button
                            onClick={handleAddMilestoneClick}
                            className="absolute top-4 left-[24rem] flex items-center gap-2 bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded-full"
                        >
                            <Plus className="w-4 h-4" />
                            Adicionar Objetivo
                        </button>
                        <div className="absolute top-4 right-4 flex items-center gap-4">
                            <div className="px-4 py-2 bg-amber-700 text-amber-100 rounded-lg whitespace-nowrap">
                                {selectedCountry.label ||
                                    selectedCountry.name ||
                                    selectedCountry.id}
                            </div>
                            <button
                                onClick={() => setIsTimelineVisible(true)}
                                className="flex items-center gap-2 bg-amber-600 hover:bg-amber-700 text-white px-4 py-2 rounded-full whitespace-nowrap"
                            >
                                <AlignLeft className="w-4 h-4" />
                                Ver Linha do Tempo
                            </button>
                            <button
                                onClick={toggleFullscreen}
                                className="flex items-center gap-2 bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-full whitespace-nowrap"
                            >
                                {isFullscreen ? (
                                    <>
                                        <Minimize2 className="w-4 h-4" />
                                        Sair da Tela Cheia
                                    </>
                                ) : (
                                    <>
                                        <Maximize2 className="w-4 h-4" />
                                        Tela Cheia
                                    </>
                                )}
                            </button>
                        </div>
                        {getCountryMilestones(selectedCountry).map((m) => {
                            if (!m.label) return null;
                            return (
                                <div
                                    key={m.id}
                                    onMouseDown={(e) =>
                                        handleMouseDown(e, m.id, "milestone")
                                    }
                                    className="absolute cursor-pointer -translate-x-1/2 -translate-y-1/2 hover:scale-110 transition-transform duration-300"
                                    style={{ left: `${m.x}%`, top: `${m.y}%` }}
                                    onClick={() =>
                                        !isEditing && setSelectedLocation(m)
                                    }
                                >
                                    <div className="relative">
                                        <div
                                            className={`p-2 rounded-full bg-gray-700 shadow-lg ring-2 ${
                                                m.completed
                                                    ? "ring-green-500"
                                                    : "ring-amber-500"
                                            }`}
                                        >
                                            <TrendingUp
                                                className={`w-8 h-8 ${
                                                    m.completed
                                                        ? "text-green-500"
                                                        : "text-amber-400"
                                                }`}
                                            />
                                        </div>
                                        <div className="absolute -bottom-8 left-1/2 -translate-x-1/2 whitespace-nowrap">
                                            <span className="text-xs font-bold px-2 py-1 rounded-full shadow text-amber-100 bg-amber-700">
                                                {m.year}/{m.month}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </>
                )}

                {selectedLocation && (
                    <div
                        className="absolute w-72 bg-gray-800/95 backdrop-blur-sm p-6 rounded-xl shadow-xl border-2 border-amber-700"
                        style={{
                            left: `${selectedLocation.x}%`,
                            top: `${selectedLocation.y + 10}%`,
                            transform: "translate(-50%,0)",
                        }}
                    >
                        <div className="flex items-center gap-3 mb-4 pb-3 border-b border-amber-700">
                            <div
                                className={`p-2 rounded-lg ${
                                    selectedLocation.completed
                                        ? "bg-green-700"
                                        : "bg-gray-700"
                                }`}
                            >
                                {selectedLocation.completed ? (
                                    <CheckCircle className="w-6 h-6 text-green-300" />
                                ) : (
                                    <Clock className="w-6 h-6 text-gray-400" />
                                )}
                            </div>
                            <div>
                                <h3 className="text-lg font-bold text-amber-300">
                                    Ano {selectedLocation.year}, Mês{" "}
                                    {selectedLocation.month}
                                </h3>
                                <div className="text-sm text-amber-400">
                                    {selectedCountry?.label ||
                                        selectedCountry?.name ||
                                        selectedCountry?.id}
                                </div>
                            </div>
                        </div>
                        {selectedLocation.items?.map((item) => (
                            <div
                                key={item.id}
                                className="bg-gray-700 rounded-lg p-3 mb-2"
                            >
                                <label className="flex items-center gap-2 text-sm mb-2 cursor-pointer select-none">
                                    <div className="relative">
                                        <input
                                            type="checkbox"
                                            className="sr-only peer"
                                            checked={item.completed || false}
                                            disabled={togglingIds.includes(
                                                item.id,
                                            )}
                                            onChange={() =>
                                                handleToggleCheckbox(item)
                                            }
                                        />
                                        <div
                                            className={`w-4 h-4 border-2 rounded transition-colors ${
                                                togglingIds.includes(item.id)
                                                    ? "border-gray-500 bg-gray-700"
                                                    : item.completed
                                                      ? "border-green-500 bg-green-500"
                                                      : "border-amber-500 bg-transparent"
                                            } peer-focus:ring-2 peer-focus:ring-amber-500`}
                                        >
                                            {item.completed && (
                                                <svg
                                                    className="w-3 h-3 text-white absolute top-0.5 left-0.5"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        strokeWidth="3"
                                                        d="M5 13l4 4L19 7"
                                                    />
                                                </svg>
                                            )}
                                        </div>
                                    </div>
                                    <span
                                        className={
                                            item.completed
                                                ? "text-gray-100"
                                                : "text-gray-300"
                                        }
                                    >
                                        {item.completed
                                            ? "Concluído"
                                            : "Em andamento"}
                                    </span>
                                </label>

                                {editingMilestoneId === item.id ? (
                                    <>
                                        <div className="mb-2">
                                            <label className="block text-sm text-amber-100 mb-1">
                                                Título
                                            </label>
                                            <input
                                                type="text"
                                                className="w-full p-2 rounded bg-gray-600 text-amber-100"
                                                value={editedMilestoneTitle}
                                                onChange={(e) =>
                                                    setEditedMilestoneTitle(
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="mb-2">
                                            <label className="block text-sm text-amber-100 mb-1">
                                                Ano
                                            </label>
                                            <input
                                                type="number"
                                                className="w-full p-2 rounded bg-gray-600 text-amber-100"
                                                value={editedMilestoneYear}
                                                onChange={(e) =>
                                                    setEditedMilestoneYear(
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                        </div>
                                        <div className="flex justify-end gap-2">
                                            <button
                                                onClick={() =>
                                                    setEditingMilestoneId(null)
                                                }
                                                className="px-3 py-1 bg-gray-500 text-white rounded hover:bg-gray-600"
                                            >
                                                Cancelar
                                            </button>
                                            <button
                                                onClick={() =>
                                                    handleSaveEditingMilestone(
                                                        item,
                                                    )
                                                }
                                                className="px-3 py-1 bg-green-600 text-white rounded hover:bg-green-700"
                                            >
                                                Salvar
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <div className="flex justify-between items-center">
                                        <div className="text-sm text-amber-100 font-semibold">
                                            {item.label
                                                ?.split(":")[1]
                                                ?.trim() || ""}
                                        </div>
                                        <div className="flex gap-2">
                                            <button
                                                onClick={() =>
                                                    startEditingMilestone(item)
                                                }
                                                className="text-blue-300 hover:text-blue-100"
                                            >
                                                <Edit className="w-5 h-5" />
                                            </button>
                                            <button
                                                onClick={() =>
                                                    handleDeleteMilestone(item)
                                                }
                                                className="text-red-300 hover:text-red-100"
                                            >
                                                <Trash2 className="w-5 h-5" />
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                setSelectedLocation(null);
                            }}
                            className="absolute top-2 left-2 p-1 text-amber-400 hover:text-amber-200 rounded-full hover:bg-gray-700"
                        >
                            ×
                        </button>
                    </div>
                )}
            </div>

            {isAddingMilestone && (
                <div className="absolute inset-0 flex items-center justify-center bg-black/50">
                    <div className="w-96 bg-gray-800 p-6 rounded-xl shadow-xl border-2 border-amber-700 relative">
                        <h3 className="text-lg font-bold text-amber-300 mb-4">
                            Novo Objetivo em {selectedCountry?.label}
                        </h3>
                        <div className="mb-2">
                            <label className="block text-sm text-amber-100 mb-1">
                                Título do Objetivo
                            </label>
                            <input
                                type="text"
                                className="w-full p-2 rounded bg-gray-700 text-amber-100"
                                value={newMilestoneTitle}
                                onChange={(e) =>
                                    setNewMilestoneTitle(e.target.value)
                                }
                            />
                        </div>
                        <div className="flex gap-2 mb-4">
                            <div>
                                <label className="block text-sm text-amber-100 mb-1">
                                    Ano
                                </label>
                                <input
                                    type="number"
                                    className="w-full p-2 rounded bg-gray-700 text-amber-100"
                                    value={newMilestoneYear}
                                    onChange={(e) =>
                                        setNewMilestoneYear(e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <label className="block text-sm text-amber-100 mb-1">
                                    Mês
                                </label>
                                <input
                                    type="number"
                                    className="w-full p-2 rounded bg-gray-700 text-amber-100"
                                    value={newMilestoneMonth}
                                    onChange={(e) =>
                                        setNewMilestoneMonth(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="flex justify-end gap-2">
                            <button
                                onClick={() => setIsAddingMilestone(false)}
                                className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={handleSaveNewMilestone}
                                className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
                            >
                                Salvar
                            </button>
                        </div>
                        <button
                            onClick={() => setIsAddingMilestone(false)}
                            className="absolute top-2 right-2 p-1 text-amber-400 hover:text-amber-200 rounded-full hover:bg-gray-700"
                        >
                            ×
                        </button>
                    </div>
                </div>
            )}

            {deletingItem && (
                <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
                    <div className="w-96 bg-gray-800 p-6 rounded-xl shadow-xl border-2 border-amber-700">
                        <div className="flex items-center gap-3 mb-4 pb-3 border-b border-amber-700">
                            <div className="p-2 rounded-lg bg-red-700/30">
                                <Trash2 className="w-6 h-6 text-red-300" />
                            </div>
                            <h3 className="text-lg font-bold text-amber-300">
                                Confirmar Deleção
                            </h3>
                        </div>
                        <p className="text-amber-100 mb-6">
                            Tem certeza que deseja deletar o objetivo:
                            <span className="block mt-2 font-semibold text-amber-200">
                                "{deletingItem.label?.split(":")[1]?.trim()}"
                            </span>
                        </p>
                        <div className="flex justify-end gap-3">
                            <button
                                onClick={() => setDeletingItem(null)}
                                className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={confirmDelete}
                                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                            >
                                Deletar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
