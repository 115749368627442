// Journals.js

import React, { useState, useEffect, useCallback, useMemo } from "react";
import { X, Edit, Trash2, Save, Plus, Mic, Pause } from "lucide-react";
import { Card, Button } from "./AllComponents";
import axios from "axios";
import { useNotification } from "./NotificationContext";

const API_BASE_URL = "https://organifyhub.com:5003/api";

const Journals = ({ onBack, searchTerm }) => {
    const { showNotification } = useNotification();
    const [journals, setJournals] = useState([]);
    const [editingItem, setEditingItem] = useState(null);
    const [deletingItem, setDeletingItem] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorderRef = React.useRef(null);
    const audioChunksRef = React.useRef([]);

    const fetchJournals = useCallback(async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/journals`);
            setJournals(response.data);
        } catch (error) {
            console.error("Error fetching journals:", error);
            showNotification(
                "Erro ao carregar os diários. Por favor, tente novamente.",
                "error",
            );
        }
    }, [showNotification]);

    useEffect(() => {
        fetchJournals();
    }, [fetchJournals]);

    const handleEdit = (journal) => {
        setEditingItem(journal);
    };

    const handleAddNew = () => {
        setEditingItem({
            title: "",
            content: "",
            date: new Date().toISOString().split("T")[0],
            isPinned: false,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
        });
    };

    const handleSave = useCallback(async () => {
        if (!editingItem) {
            console.error("No item to save");
            showNotification("Erro: Nenhum item para salvar", "error");
            return;
        }

        try {
            const method = editingItem._id ? "PUT" : "POST";
            const url = editingItem._id
                ? `${API_BASE_URL}/journals/${editingItem._id}`
                : `${API_BASE_URL}/journals`;

            let dataToSend = { ...editingItem };
            delete dataToSend._id;

            const response = await axios({
                method: method,
                url: url,
                data: dataToSend,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            if (response.status === 200 || response.status === 201) {
                showNotification("Diário salvo com sucesso!", "success");
                await fetchJournals();
                setEditingItem(null);
            } else {
                showNotification(
                    "Erro ao salvar o diário. Por favor, tente novamente.",
                    "error",
                );
            }
        } catch (error) {
            console.error("Error saving journal:", error);
            showNotification(
                "Erro ao salvar o diário. Por favor, tente novamente.",
                "error",
            );
        }
    }, [editingItem, showNotification, fetchJournals]);

    const handleDelete = (journal) => {
        setDeletingItem(journal);
    };

    const confirmDelete = async () => {
        if (!deletingItem) {
            console.error("No item selected for deletion");
            showNotification(
                "Erro: Nenhum item selecionado para exclusão",
                "error",
            );
            return;
        }

        try {
            const response = await axios.delete(
                `${API_BASE_URL}/journals/${deletingItem._id}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                },
            );

            if (response.status === 204) {
                setJournals(journals.filter((j) => j._id !== deletingItem._id));
                setDeletingItem(null);
                showNotification("Diário excluído com sucesso!", "success");
            } else {
                console.error("Unexpected response status:", response.status);
                showNotification(
                    "Erro inesperado ao excluir o diário",
                    "error",
                );
            }
        } catch (error) {
            console.error("Error deleting journal:", error);
            showNotification(
                "Erro ao excluir o diário. Por favor, tente novamente.",
                "error",
            );
        } finally {
            setDeletingItem(null);
        }
    };

    const handleRecordToggle = async () => {
        if (!isRecording) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                });
                mediaRecorderRef.current = new MediaRecorder(stream);
                audioChunksRef.current = [];
                mediaRecorderRef.current.ondataavailable = (event) => {
                    audioChunksRef.current.push(event.data);
                };
                mediaRecorderRef.current.onstop = async () => {
                    const audioBlob = new Blob(audioChunksRef.current, {
                        type: "audio/wav",
                    });
                    await handleAudioTranscription(audioBlob);
                    stream.getTracks().forEach((track) => track.stop());
                };
                mediaRecorderRef.current.start();
                setIsRecording(true);
            } catch (error) {
                console.error("Erro ao iniciar a gravação:", error);
                showNotification(
                    "Erro ao iniciar a gravação. Verifique as permissões do microfone.",
                    "error",
                );
            }
        } else {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
        }
    };

    const handleAudioTranscription = async (audioBlob) => {
        try {
            const formData = new FormData();
            formData.append("audio", audioBlob, "audio.wav");

            const token = localStorage.getItem("token");

            const response = await fetch(`${API_BASE_URL}/transcribe-audio`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Erro na transcrição do áudio");
            }
            const transcriptionResult = await response.json();
            if (editingItem) {
                setEditingItem((prev) => ({
                    ...prev,
                    content:
                        prev.content + "\n" + transcriptionResult.transcription,
                }));
            }
            showNotification("Áudio transcrito com sucesso!", "success");
        } catch (error) {
            console.error("Erro na transcrição do áudio:", error);
            showNotification(
                "Erro na transcrição do áudio. Por favor, tente novamente.",
                "error",
            );
        }
    };

    const filteredItems = useMemo(() => {
        if (!searchTerm) {
            return journals;
        }

        const searchRegex = new RegExp(searchTerm, "i");

        return journals.filter(
            (item) =>
                searchRegex.test(item.title) ||
                searchRegex.test(item.content || ""),
        );
    }, [searchTerm, journals]);

    return (
        <div className="space-y-4 relative">
            {editingItem ? (
                <div className="space-y-4 relative">
                    <h2 className="text-2xl font-bold">
                        {editingItem._id ? "Editando" : "Novo"} Diário:{" "}
                        {editingItem.title}
                    </h2>
                    <input
                        type="text"
                        className="w-full p-2 bg-input rounded-lg text-foreground resize-none border-border focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                        value={editingItem.title}
                        onChange={(e) =>
                            setEditingItem({
                                ...editingItem,
                                title: e.target.value,
                            })
                        }
                        placeholder="Título"
                    />
                    <input
                        type="date"
                        className="w-full p-2 bg-input rounded-lg text-foreground resize-none border-border focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                        value={editingItem.date}
                        onChange={(e) =>
                            setEditingItem({
                                ...editingItem,
                                date: e.target.value,
                            })
                        }
                    />
                    <textarea
                        className="w-full h-[60vh] p-4 bg-input rounded-lg text-foreground resize-none border-border focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                        value={editingItem.content}
                        onChange={(e) =>
                            setEditingItem({
                                ...editingItem,
                                content: e.target.value,
                            })
                        }
                        placeholder="Conteúdo"
                    />
                    <button
                        onClick={handleRecordToggle}
                        className="absolute bottom-4 right-4 p-2 bg-secondary hover:bg-secondary/80 rounded-full"
                        title={
                            isRecording ? "Parar gravação" : "Iniciar gravação"
                        }
                    >
                        {isRecording ? <Pause size={24} /> : <Mic size={24} />}
                    </button>
                    <Button
                        onClick={handleSave}
                        className="bg-primary hover:bg-primary/80 text-primary-foreground"
                        title="Salvar"
                    >
                        <Save size={18} className="mr-2" />
                        Salvar
                    </Button>
                </div>
            ) : (
                <>
                    <h2 className="text-2xl font-bold mb-6">Journaling</h2>
                    <div className="flex flex-wrap gap-4 mb-4">
                        <Button
                            onClick={handleAddNew}
                            className="bg-primary hover:bg-primary/80 text-primary-foreground"
                            title="Adicionar Novo"
                        >
                            <Plus size={18} className="mr-2" />
                            Adicionar Novo
                        </Button>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                        {filteredItems.map((item) => (
                            <Card
                                key={item._id}
                                className="flex flex-col justify-between"
                            >
                                <div>
                                    <h3 className="text-lg font-semibold mb-2">
                                        {item.title}
                                    </h3>
                                    <p className="text-sm text-muted-foreground mb-2">
                                        Data:{" "}
                                        {new Date(
                                            item.date,
                                        ).toLocaleDateString()}
                                    </p>
                                    <p className="text-sm text-muted-foreground mb-4 line-clamp-3">
                                        {item.content}
                                    </p>
                                </div>
                                <div className="flex justify-end space-x-2">
                                    <Button
                                        onClick={() => handleEdit(item)}
                                        className="p-1 bg-secondary hover:bg-secondary/80 text-secondary-foreground"
                                        title="Editar item"
                                    >
                                        <Edit size={14} />
                                    </Button>
                                    <Button
                                        onClick={() => handleDelete(item)}
                                        className="p-1 bg-destructive hover:bg-destructive/80 text-destructive-foreground"
                                        title="Excluir item"
                                    >
                                        <Trash2 size={14} />
                                    </Button>
                                </div>
                            </Card>
                        ))}
                    </div>
                </>
            )}

            {deletingItem && (
                <div className="fixed inset-0 bg-background/80 backdrop-blur-sm flex items-center justify-center">
                    <div className="bg-card text-card-foreground p-6 rounded-lg">
                        <h3 className="text-xl font-bold mb-4">
                            Confirmar exclusão
                        </h3>
                        <p className="mb-4 text-muted-foreground">
                            Tem certeza que deseja excluir "{deletingItem.title}
                            "?
                        </p>
                        <div className="flex justify-end space-x-2">
                            <Button
                                onClick={() => setDeletingItem(null)}
                                className="bg-secondary hover:bg-secondary/80 text-secondary-foreground"
                                title="Cancelar exclusão"
                            >
                                Cancelar
                            </Button>
                            <Button
                                onClick={confirmDelete}
                                className="bg-destructive hover:bg-destructive/80 text-destructive-foreground"
                                title="Confirmar exclusão"
                            >
                                Confirmar
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Journals;
