import React, { useState, useEffect } from "react";

const TaskCreationForm = ({
    onSaveTask,
    onCancel,
    objectives = [],
    existingTask,
}) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [objectiveId, setObjectiveId] = useState("");
    const [startDate, setStartDate] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [priority, setPriority] = useState("medium");
    const [status, setStatus] = useState("pending");

    useEffect(() => {
        if (existingTask) {
            setTitle(existingTask.title || "");
            setDescription(existingTask.description || "");
            setObjectiveId(existingTask.objectiveId || "");
            setStartDate(existingTask.startDate || "");
            setDueDate(existingTask.dueDate || "");
            setPriority(existingTask.priority || "medium");
            setStatus(existingTask.status || "pending");
        }
    }, [existingTask]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const taskData = {
            title,
            description,
            objectiveId: objectiveId || null,
            startDate: startDate || null,
            dueDate: dueDate || null,
            priority,
            status,
        };
        if (existingTask && existingTask._id) {
            taskData._id = existingTask._id;
        }
        onSaveTask(taskData);
    };

    return (
        <div className="bg-card text-card-foreground p-6 rounded-lg w-full space-y-4 relative">
            <h2 className="text-2xl font-bold mb-4">
                {existingTask ? "Editar Tarefa" : "Criar Nova Tarefa"}
            </h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label className="block mb-1 text-sm font-medium text-muted-foreground">
                        Título da Tarefa
                    </label>
                    <input
                        type="text"
                        required
                        className="w-full p-2 bg-input rounded-lg text-foreground border-border focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        placeholder="Ex: Revisar capítulo 5..."
                    />
                </div>
                <div>
                    <label className="block mb-1 text-sm font-medium text-muted-foreground">
                        Descrição
                    </label>
                    <textarea
                        className="w-full p-2 bg-input rounded-lg text-foreground resize-none border-border focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary h-20"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Detalhes sobre a tarefa..."
                    />
                </div>
                <div>
                    <label className="block mb-1 text-sm font-medium text-muted-foreground">
                        Vincular a um Objetivo
                    </label>
                    <select
                        className="w-full p-2 bg-input rounded-lg text-foreground border-border focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                        value={objectiveId || ""}
                        onChange={(e) => setObjectiveId(e.target.value)}
                    >
                        <option value="">Nenhum objetivo</option>
                        {objectives.map((obj) => (
                            <option key={obj._id} value={obj._id}>
                                {obj.title}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label className="block mb-1 text-sm font-medium text-muted-foreground">
                        Data de Início
                    </label>
                    <input
                        type="date"
                        className="w-full p-2 bg-input rounded-lg text-foreground border-border focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                        value={startDate || ""}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </div>
                <div>
                    <label className="block mb-1 text-sm font-medium text-muted-foreground">
                        Data de Fim
                    </label>
                    <input
                        type="date"
                        className="w-full p-2 bg-input rounded-lg text-foreground border-border focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                        value={dueDate || ""}
                        onChange={(e) => setDueDate(e.target.value)}
                    />
                </div>
                <div>
                    <label className="block mb-1 text-sm font-medium text-muted-foreground">
                        Prioridade
                    </label>
                    <select
                        className="w-full p-2 bg-input rounded-lg text-foreground border-border focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                        value={priority}
                        onChange={(e) => setPriority(e.target.value)}
                    >
                        <option value="low">Baixa</option>
                        <option value="medium">Média</option>
                        <option value="high">Alta</option>
                    </select>
                </div>
                <div>
                    <label className="block mb-1 text-sm font-medium text-muted-foreground">
                        Status
                    </label>
                    <select
                        className="w-full p-2 bg-input rounded-lg text-foreground border-border focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                    >
                        <option value="pending">Pendente</option>
                        <option value="inProgress">Em Progresso</option>
                        <option value="completed">Concluída</option>
                    </select>
                </div>
                <div className="flex justify-end space-x-2 pt-4">
                    <button
                        type="button"
                        onClick={onCancel}
                        className="bg-secondary hover:bg-secondary/80 text-secondary-foreground px-4 py-2 rounded-lg"
                    >
                        Cancelar
                    </button>
                    <button
                        type="submit"
                        className="bg-primary hover:bg-primary/80 text-primary-foreground px-4 py-2 rounded-lg"
                    >
                        {existingTask ? "Salvar Alterações" : "Criar Tarefa"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default TaskCreationForm;
