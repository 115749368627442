// Objectives.js

import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
    Edit,
    Trash2,
    Save,
    Plus,
    Wand2,
    Check,
    Calendar as CalendarIcon,
} from "lucide-react";
import ObjectiveTasksDropdown from "./ObjectiveTasksDropdown";
import { Card, Button, formatDate, adjustDate } from "./AllComponents";
import axios from "axios";
import { useNotification } from "./NotificationContext";

const API_BASE_URL = "https://organifyhub.com:5003/api";

const Objectives = ({
    searchTerm,
    cycles,
    fetchCycles,
    tasks,
    fetchTasks,
    handleAddNewTask,
    handleEditTask,
    handleDeleteTask,
    handleToggleComplete,
}) => {
    const { showNotification } = useNotification();
    const [objectives, setObjectives] = useState([]);
    const [editingItem, setEditingItem] = useState(null);
    const [deletingItem, setDeletingItem] = useState(null);
    const [selectedObjective, setSelectedObjective] = useState(null);
    const [showMagicModal, setShowMagicModal] = useState(false);
    const [magicInput, setMagicInput] = useState("");
    const [isMagicProcessing, setIsMagicProcessing] = useState(false);
    const [showMultiObjectiveSelection, setShowMultiObjectiveSelection] =
        useState(false);
    const [selectedObjectives, setSelectedObjectives] = useState([]);
    const [showMultiObjectiveMagicModal, setShowMultiObjectiveMagicModal] =
        useState(false);
    const [multiObjectiveMagicInput, setMultiObjectiveMagicInput] =
        useState("");
    const [showNoteSelection, setShowNoteSelection] = useState(false);
    const [selectedNotes, setSelectedNotes] = useState([]);
    const [notes, setNotes] = useState([]);
    const [tags] = useState([
        "profissional",
        "saúde",
        "relacionamentos",
        "finanças",
        "pessoal",
    ]);

    const fetchObjectives = useCallback(async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/objectives`);
            setObjectives(response.data);
        } catch (error) {
            console.error("Error fetching objectives:", error);
            showNotification(
                "Erro ao carregar os objetivos. Por favor, tente novamente.",
                "error",
            );
        }
    }, [showNotification]);

    const fetchNotes = useCallback(async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/notes`);
            setNotes(response.data);
        } catch (error) {
            console.error("Error fetching notes:", error);
            showNotification(
                "Erro ao carregar as notas. Por favor, tente novamente.",
                "error",
            );
        }
    }, [showNotification]);

    useEffect(() => {
        fetchObjectives();
        fetchNotes();
    }, [fetchObjectives, fetchNotes]);

    const handleEdit = (item) => {
        setEditingItem({
            ...item,
            cycleId: item.cycleId || "",
            cycle: item.cycle || "",
        });
    };

    const handleAddNew = () => {
        setEditingItem({
            title: "",
            description: "",
            tags: [],
            startDate: adjustDate(new Date().toISOString().split("T")[0]),
            endDate: "",
            timeframe: "curto",
            isPinned: false,
            cycleId: "",
            cycle: "",
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
        });
    };

    const handleSave = useCallback(async () => {
        if (!editingItem) {
            console.error("No item to save");
            showNotification("Erro: Nenhum item para salvar", "error");
            return;
        }

        try {
            const method = editingItem._id ? "PUT" : "POST";
            const url = editingItem._id
                ? `${API_BASE_URL}/objectives/${editingItem._id}`
                : `${API_BASE_URL}/objectives`;

            let dataToSend = { ...editingItem };
            if (
                dataToSend.startDate &&
                typeof dataToSend.startDate === "string"
            ) {
                dataToSend.startDate = new Date(
                    dataToSend.startDate,
                ).toISOString();
            }
            if (dataToSend.endDate && typeof dataToSend.endDate === "string") {
                dataToSend.endDate = new Date(dataToSend.endDate).toISOString();
            }
            delete dataToSend._id;

            const response = await axios({
                method: method,
                url: url,
                data: dataToSend,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });

            if (response.status === 200 || response.status === 201) {
                showNotification("Objetivo salvo com sucesso!", "success");
                await fetchObjectives();
                await fetchCycles();
                setEditingItem(null);
            } else {
                showNotification(
                    "Erro ao salvar o objetivo. Por favor, tente novamente.",
                    "error",
                );
            }
        } catch (error) {
            console.error("Error saving objective:", error);
            showNotification(
                "Erro ao salvar o objetivo. Por favor, tente novamente.",
                "error",
            );
        }
    }, [editingItem, showNotification, fetchObjectives, fetchCycles]);

    const handleDelete = (item) => {
        setDeletingItem(item);
    };

    const confirmDelete = async () => {
        if (!deletingItem) {
            console.error("No item selected for deletion");
            showNotification(
                "Erro: Nenhum item selecionado para exclusão",
                "error",
            );
            return;
        }

        try {
            const response = await axios.delete(
                `${API_BASE_URL}/objectives/${deletingItem._id}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                },
            );

            if (response.status === 204) {
                setObjectives(
                    objectives.filter((obj) => obj._id !== deletingItem._id),
                );
                setDeletingItem(null);
                showNotification("Objetivo excluído com sucesso!", "success");
            } else {
                console.error("Unexpected response status:", response.status);
                showNotification(
                    "Erro inesperado ao excluir o objetivo",
                    "error",
                );
            }
        } catch (error) {
            console.error("Error deleting objective:", error);
            showNotification(
                "Erro ao excluir o objetivo. Por favor, tente novamente.",
                "error",
            );
        } finally {
            setDeletingItem(null);
        }
    };

    const handleObjectiveMagicWandClick = (objective) => {
        setSelectedObjective(objective);
        setShowMagicModal(true);
    };

    const handleMagicConfirm = async () => {
        setIsMagicProcessing(true);
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(
                `${API_BASE_URL}/magic-wand-objective`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        objectiveId: selectedObjective._id,
                        userInput: magicInput,
                    }),
                },
            );

            if (!response.ok) {
                throw new Error("Failed to process magic wand request");
            }

            const result = await response.json();

            setObjectives(
                objectives.map((obj) =>
                    obj._id === result._id ? result : obj,
                ),
            );

            setShowMagicModal(false);
            setMagicInput("");
            setSelectedObjective(null);
            showNotification("Objetivo atualizado com sucesso!", "success");
        } catch (error) {
            console.error("Error processing magic wand request:", error);
            showNotification(
                "Erro ao processar a solicitação da varinha mágica. Por favor, tente novamente.",
                "error",
            );
        } finally {
            setIsMagicProcessing(false);
        }
    };

    const handleMultiObjectiveMagicWandClick = () => {
        setShowMultiObjectiveSelection(!showMultiObjectiveSelection);
        if (showMultiObjectiveSelection) {
            setSelectedObjectives([]);
            setSelectedNotes([]);
            setShowNoteSelection(false);
            setShowMultiObjectiveMagicModal(false);
        }
    };

    const handleObjectiveSelection = (objectiveId) => {
        setSelectedObjectives((prev) =>
            prev.includes(objectiveId)
                ? prev.filter((id) => id !== objectiveId)
                : [...prev, objectiveId],
        );
    };

    const handleMultiObjectiveMagicConfirm = () => {
        setShowMultiObjectiveMagicModal(true);
    };

    const handleMultiObjectiveMagicSubmit = async () => {
        setIsMagicProcessing(true);
        try {
            const token = localStorage.getItem("token");
            const response = await fetch(
                `${API_BASE_URL}/magic-wand-multi-objective`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        objectiveIds: selectedObjectives,
                        noteIds: selectedNotes,
                        userInput: multiObjectiveMagicInput,
                    }),
                },
            );

            if (!response.ok) {
                throw new Error(
                    "Failed to process magic wand request for multiple objectives",
                );
            }

            const updatedObjectives = await response.json();

            setObjectives((prevObjectives) =>
                prevObjectives.map((obj) => {
                    const updatedObj = updatedObjectives.find(
                        (updated) => updated._id === obj._id,
                    );
                    return updatedObj ? updatedObj : obj;
                }),
            );

            setShowMultiObjectiveMagicModal(false);
            setMultiObjectiveMagicInput("");
            setSelectedObjectives([]);
            setSelectedNotes([]);
            setShowNoteSelection(false);

            showNotification(
                "Múltiplos objetivos atualizados com sucesso!",
                "success",
            );

            await fetchObjectives();
        } catch (error) {
            console.error(
                "Error processing magic wand request for multiple objectives:",
                error,
            );
            showNotification(
                "Erro ao processar a solicitação da varinha mágica para múltiplos objetivos. Por favor, tente novamente.",
                "error",
            );
        } finally {
            setIsMagicProcessing(false);
        }
    };

    const filteredItems = useMemo(() => {
        if (!searchTerm) {
            return objectives;
        }

        const searchRegex = new RegExp(searchTerm, "i");

        return objectives.filter(
            (item) =>
                searchRegex.test(item.title) ||
                searchRegex.test(item.description || "") ||
                (item.tags && item.tags.some((tag) => searchRegex.test(tag))),
        );
    }, [searchTerm, objectives]);

    return (
        <div className="space-y-4 relative">
            {editingItem ? (
                <div className="space-y-4 relative">
                    <h2 className="text-2xl font-bold">
                        {editingItem._id ? "Editando" : "Novo"}:{" "}
                        {editingItem.title}
                    </h2>
                    <input
                        type="text"
                        className="w-full p-2 bg-input rounded-lg text-foreground resize-none border-border focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                        value={editingItem.title}
                        onChange={(e) =>
                            setEditingItem({
                                ...editingItem,
                                title: e.target.value,
                            })
                        }
                        placeholder="Título"
                    />
                    {/* Tags */}
                    <div>
                        <h3 className="text-lg font-semibold mb-2">Tags</h3>
                        <div className="flex flex-wrap gap-2 mb-4">
                            {tags.map((tag) => (
                                <button
                                    key={tag}
                                    onClick={() => {
                                        const updatedTags =
                                            editingItem.tags?.includes(tag)
                                                ? editingItem.tags.filter(
                                                      (t) => t !== tag,
                                                  )
                                                : [
                                                      ...(editingItem.tags ||
                                                          []),
                                                      tag,
                                                  ];
                                        setEditingItem({
                                            ...editingItem,
                                            tags: updatedTags,
                                        });
                                    }}
                                    className={`px-2 py-1 rounded-full text-sm ${
                                        editingItem.tags?.includes(tag)
                                            ? "bg-primary text-primary-foreground"
                                            : "bg-secondary text-secondary-foreground"
                                    }`}
                                >
                                    {tag}
                                </button>
                            ))}
                        </div>
                    </div>
                    {/* Data de Início */}
                    <div>
                        <label className="block text-sm font-medium text-muted-foreground mb-1">
                            Data de Início
                        </label>
                        <input
                            type="date"
                            className="w-full p-2 bg-input rounded-lg text-foreground resize-none border-border focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                            value={adjustDate(editingItem.startDate) || ""}
                            onChange={(e) =>
                                setEditingItem({
                                    ...editingItem,
                                    startDate: e.target.value,
                                })
                            }
                        />
                    </div>
                    {/* Data de Fim */}
                    <div>
                        <label className="block text-sm font-medium text-muted-foreground mb-1">
                            Data de Fim
                        </label>
                        <input
                            type="date"
                            className="w-full p-2 bg-input rounded-lg text-foreground resize-none border-border focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                            value={adjustDate(editingItem.endDate) || ""}
                            onChange={(e) =>
                                setEditingItem({
                                    ...editingItem,
                                    endDate: e.target.value,
                                })
                            }
                        />
                    </div>
                    {/* Prazo */}
                    <div>
                        <label className="block text-sm font-medium text-muted-foreground mb-1">
                            Prazo
                        </label>
                        <select
                            className="w-full p-2 bg-input rounded-lg text-foreground resize-none border-border focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                            value={editingItem.timeframe || "curto"}
                            onChange={(e) =>
                                setEditingItem({
                                    ...editingItem,
                                    timeframe: e.target.value,
                                })
                            }
                        >
                            <option value="curto">
                                Curto prazo (1 dia a 1 ano)
                            </option>
                            <option value="medio">
                                Médio prazo (1 a 5 anos)
                            </option>
                            <option value="longo">
                                Longo prazo (5 a 10 anos)
                            </option>
                        </select>
                    </div>
                    {/* Seleção de Ciclo */}
                    <div>
                        <label className="block text-sm font-medium text-muted-foreground mb-1">
                            Ciclo
                        </label>
                        {cycles.length > 0 ? (
                            <select
                                className="w-full p-2 bg-input rounded-lg text-foreground border-border focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                                value={editingItem.cycleId || ""}
                                onChange={(e) => {
                                    const selectedCycleId = e.target.value;
                                    const selectedCycle = cycles.find(
                                        (cycle) =>
                                            cycle._id === selectedCycleId,
                                    );
                                    setEditingItem({
                                        ...editingItem,
                                        cycleId: selectedCycleId,
                                        cycle: selectedCycle
                                            ? `${selectedCycle.cycle} - ${selectedCycle.year}`
                                            : "",
                                    });
                                }}
                            >
                                <option value="">Selecione um ciclo</option>
                                {cycles.map((cycle) => (
                                    <option
                                        key={cycle._id}
                                        value={cycle._id}
                                        selected={
                                            cycle._id === editingItem.cycleId
                                        }
                                    >
                                        {`${cycle.cycle} - ${cycle.year}`}
                                    </option>
                                ))}
                            </select>
                        ) : (
                            <p>Carregando ciclos...</p>
                        )}
                    </div>
                    {/* Descrição */}
                    <textarea
                        className="w-full h-[60vh] p-4 bg-input rounded-lg text-foreground resize-none border-border focus:outline-none focus:border-primary focus:ring-1 focus:ring-primary"
                        value={editingItem.description}
                        onChange={(e) =>
                            setEditingItem({
                                ...editingItem,
                                description: e.target.value,
                            })
                        }
                        placeholder="Descrição"
                    />
                    <Button
                        onClick={handleSave}
                        className="bg-primary hover:bg-primary/80 text-primary-foreground"
                        title="Salvar"
                    >
                        <Save size={18} className="mr-2" />
                        Salvar
                    </Button>
                </div>
            ) : (
                <>
                    <h2 className="text-2xl font-bold mb-6">Objetivos</h2>
                    <div className="flex flex-wrap gap-4 mb-4">
                        <Button
                            onClick={handleAddNew}
                            className="bg-primary hover:bg-primary/80 text-primary-foreground"
                            title="Adicionar Novo"
                        >
                            <Plus size={18} className="mr-2" />
                            Adicionar Novo
                        </Button>
                        <Button
                            onClick={handleMultiObjectiveMagicWandClick}
                            className="bg-secondary hover:bg-secondary/80 text-secondary-foreground"
                            title="Magia para Múltiplos Objetivos"
                        >
                            <Wand2 size={18} className="mr-2" />
                            {showMultiObjectiveSelection
                                ? "Cancelar Seleção"
                                : "Magia para Múltiplos Objetivos"}
                        </Button>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 auto-rows-fr">
                        {filteredItems.map((obj) => (
                            <Card
                                key={obj._id}
                                className={`flex flex-col relative p-3 cursor-pointer hover:bg-accent transition-all ${
                                    selectedObjectives.includes(obj._id)
                                        ? "border-2 border-primary"
                                        : ""
                                }`}
                                onClick={(e) => {
                                    if (
                                        showMultiObjectiveSelection &&
                                        !e.target.closest("button")
                                    ) {
                                        handleObjectiveSelection(obj._id);
                                    }
                                }}
                            >
                                <div className="flex flex-col h-full">
                                    <div className="flex justify-between items-start mb-2">
                                        <h3 className="text-sm sm:text-base font-semibold line-clamp-2 flex-1 mr-2">
                                            {obj.title}
                                        </h3>
                                        <div className="flex space-x-1 shrink-0">
                                            <Button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleEdit(obj);
                                                }}
                                                className="p-1 sm:p-1.5 bg-secondary hover:bg-secondary/80 text-secondary-foreground"
                                                title="Editar objetivo"
                                            >
                                                <Edit className="w-3 h-3 sm:w-4 sm:h-4 md:w-5 md:h-5" />
                                            </Button>
                                            <Button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleObjectiveMagicWandClick(
                                                        obj,
                                                    );
                                                }}
                                                className="p-1 sm:p-1.5 bg-primary hover:bg-primary/80 text-primary-foreground"
                                                title="Aplicar magia ao objetivo"
                                            >
                                                <Wand2 className="w-3 h-3 sm:w-4 sm:h-4 md:w-5 md:h-5" />
                                            </Button>
                                            <Button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDelete(obj);
                                                }}
                                                className="p-1 sm:p-1.5 bg-destructive hover:bg-destructive/80 text-destructive-foreground"
                                                title="Excluir objetivo"
                                            >
                                                <Trash2 className="w-3 h-3 sm:w-4 sm:h-4 md:w-5 md:h-5" />
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap mt-1 mb-2">
                                        {obj.tags &&
                                            obj.tags.map((tag) => (
                                                <span
                                                    key={tag}
                                                    className="tag text-xs px-2 py-0.5 mr-1 mb-1 bg-secondary rounded-full"
                                                >
                                                    {tag}
                                                </span>
                                            ))}
                                    </div>
                                    <p className="text-xs sm:text-sm text-muted-foreground mb-2 line-clamp-3">
                                        {obj.description}
                                    </p>
                                    <div className="text-xs sm:text-sm text-muted-foreground mb-2 space-y-1">
                                        <div className="flex items-center">
                                            <CalendarIcon
                                                className="mr-1.5 text-purple-400"
                                                size={14}
                                            />
                                            <span>
                                                Início:{" "}
                                                {obj.startDate
                                                    ? formatDate(obj.startDate)
                                                    : "N/D"}
                                            </span>
                                        </div>
                                        <div className="flex items-center">
                                            <CalendarIcon
                                                className="mr-1.5 text-purple-400"
                                                size={14}
                                            />
                                            <span>
                                                Fim:{" "}
                                                {obj.endDate
                                                    ? formatDate(obj.endDate)
                                                    : "N/D"}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="mt-auto mb-2">
                                        <span className="timeframe text-xs sm:text-sm px-2 py-0.5 bg-purple-500 text-white rounded-full">
                                            Prazo:{" "}
                                            {obj.timeframe === "curto"
                                                ? "Curto"
                                                : obj.timeframe === "medio"
                                                ? "Médio"
                                                : "Longo"}
                                        </span>
                                    </div>
                                    <ObjectiveTasksDropdown
                                        tasks={tasks.filter(
                                            (task) =>
                                                task.objectiveId === obj._id,
                                        )}
                                        onAddTask={() =>
                                            handleAddNewTask(obj._id)
                                        }
                                        onToggleComplete={handleToggleComplete}
                                        onEditTask={handleEditTask}
                                        onDeleteTask={handleDeleteTask}
                                    />
                                </div>
                            </Card>
                        ))}
                    </div>
                    {showMultiObjectiveSelection &&
                        selectedObjectives.length > 0 && (
                            <Button
                                onClick={handleMultiObjectiveMagicConfirm}
                                className="mt-4 bg-primary hover:bg-primary/80 text-primary-foreground"
                                title="Confirmar seleção de objetivos"
                            >
                                <Check size={18} className="mr-2" />
                                Confirmar Seleção ({selectedObjectives.length})
                            </Button>
                        )}
                </>
            )}

            {deletingItem && (
                <div className="fixed inset-0 bg-background/80 backdrop-blur-sm flex items-center justify-center">
                    <div className="bg-card text-card-foreground p-6 rounded-lg">
                        <h3 className="text-xl font-bold mb-4">
                            Confirmar exclusão
                        </h3>
                        <p className="mb-4 text-muted-foreground">
                            Tem certeza que deseja excluir "{deletingItem.title}
                            "?
                        </p>
                        <div className="flex justify-end space-x-2">
                            <Button
                                onClick={() => setDeletingItem(null)}
                                className="bg-secondary hover:bg-secondary/80 text-secondary-foreground"
                                title="Cancelar exclusão"
                            >
                                Cancelar
                            </Button>
                            <Button
                                onClick={confirmDelete}
                                className="bg-destructive hover:bg-destructive/80 text-destructive-foreground"
                                title="Confirmar exclusão"
                            >
                                Confirmar
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            {showMagicModal && (
                <div className="fixed inset-0 bg-background/80 backdrop-blur-sm flex items-center justify-center z-50">
                    <div className="bg-card text-card-foreground p-6 rounded-lg max-w-2xl w-full">
                        <h3 className="text-xl font-semibold mb-4">
                            Melhorar Objetivo: {selectedObjective?.title}
                        </h3>
                        <textarea
                            className="w-full h-32 p-2 mb-4 bg-input text-foreground rounded-lg resize-none"
                            value={magicInput}
                            onChange={(e) => setMagicInput(e.target.value)}
                            placeholder="Descreva como você gostaria de melhorar este objetivo..."
                        />
                        <div className="flex justify-end space-x-2">
                            <Button
                                onClick={() => {
                                    setShowMagicModal(false);
                                    setMagicInput("");
                                    setSelectedObjective(null);
                                }}
                                className="bg-secondary hover:bg-secondary/80 text-secondary-foreground"
                                title="Cancelar melhoria"
                            >
                                Cancelar
                            </Button>
                            <Button
                                onClick={handleMagicConfirm}
                                className="bg-primary hover:bg-primary/80 text-primary-foreground"
                                disabled={isMagicProcessing}
                                title="Confirmar melhoria"
                            >
                                {isMagicProcessing
                                    ? "Processando..."
                                    : "Confirmar Solicitação"}
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            {showMultiObjectiveMagicModal && (
                <div className="fixed inset-0 bg-background/80 backdrop-blur-sm flex items-center justify-center z-50">
                    <div className="bg-card text-card-foreground p-6 rounded-lg max-w-2xl w-full">
                        <h3 className="text-xl font-semibold mb-4">
                            Melhorar Múltiplos Objetivos
                        </h3>
                        <p className="mb-2 text-muted-foreground">
                            Objetivos selecionados: {selectedObjectives.length}
                        </p>
                        <textarea
                            className="w-full h-32 p-2 mb-4 bg-input text-foreground rounded-lg resize-none"
                            value={multiObjectiveMagicInput}
                            onChange={(e) =>
                                setMultiObjectiveMagicInput(e.target.value)
                            }
                            placeholder="Descreva como você gostaria de melhorar estes objetivos..."
                        />
                        <div className="mb-4">
                            <label className="flex items-center">
                                <input
                                    type="checkbox"
                                    checked={showNoteSelection}
                                    onChange={() =>
                                        setShowNoteSelection(!showNoteSelection)
                                    }
                                    className="mr-2"
                                />
                                Incluir anotações relacionadas
                            </label>
                        </div>
                        {showNoteSelection && (
                            <div className="mb-4">
                                <p className="mb-2">
                                    Selecione as anotações relacionadas:
                                </p>
                                <div className="max-h-40 overflow-y-auto">
                                    {notes.map((note) => (
                                        <label
                                            key={note._id}
                                            className="flex items-center mb-2"
                                        >
                                            <input
                                                type="checkbox"
                                                checked={selectedNotes.includes(
                                                    note._id,
                                                )}
                                                onChange={() => {
                                                    setSelectedNotes((prev) =>
                                                        prev.includes(note._id)
                                                            ? prev.filter(
                                                                  (id) =>
                                                                      id !==
                                                                      note._id,
                                                              )
                                                            : [
                                                                  ...prev,
                                                                  note._id,
                                                              ],
                                                    );
                                                }}
                                                className="mr-2"
                                            />
                                            {note.title}
                                        </label>
                                    ))}
                                </div>
                            </div>
                        )}
                        <div className="flex justify-end space-x-2">
                            <Button
                                onClick={() => {
                                    setShowMultiObjectiveMagicModal(false);
                                    setMultiObjectiveMagicInput("");
                                    setSelectedObjectives([]);
                                    setSelectedNotes([]);
                                    setShowNoteSelection(false);
                                    setShowMultiObjectiveSelection(false);
                                }}
                                className="bg-secondary hover:bg-secondary/80 text-secondary-foreground"
                                title="Cancelar melhoria múltipla"
                            >
                                Cancelar
                            </Button>
                            <Button
                                onClick={handleMultiObjectiveMagicSubmit}
                                className="bg-primary hover:bg-primary/80 text-primary-foreground"
                                disabled={isMagicProcessing}
                                title="Confirmar melhoria múltipla"
                            >
                                {isMagicProcessing
                                    ? "Processando..."
                                    : "Confirmar Solicitação"}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Objectives;
