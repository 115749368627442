import React, { useState, useEffect } from "react";
import { Folder, ChevronRight, ChevronDown, File } from "lucide-react";

const CycleView = ({ onEditObjective }) => {
    const [cyclesStructure, setCyclesStructure] = useState([]);
    const [expandedYears, setExpandedYears] = useState({});
    const [expandedCycles, setExpandedCycles] = useState({});
    const [cycleObjectives, setCycleObjectives] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [currentYear, setCurrentYear] = useState(null);
    const [currentCycleId, setCurrentCycleId] = useState(null);

    useEffect(() => {
        fetchCyclesStructure();
    }, []);

    useEffect(() => {
        if (cyclesStructure.length > 0) {
            const nowYear = new Date().getFullYear();
            const foundYearData = cyclesStructure.find(
                (item) => item._id === nowYear,
            );
            if (foundYearData) {
                setCurrentYear(foundYearData._id);
                if (foundYearData.cycles.length > 0) {
                    const firstCycle = foundYearData.cycles[0];
                    setCurrentCycleId(firstCycle.cycleId);
                    fetchCycleObjectives(firstCycle.cycleId);
                }
            }
        }
    }, [cyclesStructure]);

    const fetchCyclesStructure = async () => {
        try {
            setLoading(true);
            setError(null);
            const response = await fetch(
                "https://organifyhub.com:5003/api/cycles-structure",
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                },
            );
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setCyclesStructure(Array.isArray(data) ? data : []);
        } catch (error) {
            setError(
                "Falha ao carregar os ciclos. Por favor, tente novamente mais tarde.",
            );
            setCyclesStructure([]);
        } finally {
            setLoading(false);
        }
    };

    const fetchCycleObjectives = async (cycleId) => {
        try {
            const response = await fetch(
                `https://organifyhub.com:5003/api/cycle-objectives/${cycleId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                },
            );
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setCycleObjectives((prev) => ({ ...prev, [cycleId]: data }));
        } catch {
            setCycleObjectives((prev) => ({ ...prev, [cycleId]: [] }));
        }
    };

    const toggleYear = (year) => {
        setExpandedYears((prev) => ({ ...prev, [year]: !prev[year] }));
    };

    const toggleCycle = (cycleId) => {
        setExpandedCycles((prev) => ({ ...prev, [cycleId]: !prev[cycleId] }));
        if (!cycleObjectives[cycleId]) {
            fetchCycleObjectives(cycleId);
        }
    };

    const yearData = cyclesStructure.find((item) => item._id === currentYear);
    const currentCycleData = yearData?.cycles.find(
        (cycle) => cycle.cycleId === currentCycleId,
    );
    const currentObjectives = cycleObjectives[currentCycleId];

    if (loading) {
        return <div className="p-4">Carregando ciclos...</div>;
    }

    if (error) {
        return <div className="p-4 text-red-500">{error}</div>;
    }

    return (
        <div className="p-4">
            {/* CICLO ATUAL */}
            <h2 className="text-2xl font-bold mb-4">Ciclo Atual</h2>
            {currentCycleData ? (
                <div>
                    <h3 className="text-xl font-semibold mb-4">
                        {currentCycleData.cycle} - {currentYear}
                    </h3>
                    {currentObjectives?.length > 0 ? (
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                            {currentObjectives.map((objective) => (
                                <div
                                    key={objective._id}
                                    onClick={() => onEditObjective(objective)}
                                    className="cursor-pointer border border-border p-4 rounded-lg
                             hover:border-accent transition-colors
                             w-72" // Aumentando a largura
                                >
                                    <div className="mb-2 flex items-center">
                                        {/* Ícone de arquivo maior */}
                                        <File
                                            size={24}
                                            className="mr-2 text-muted-foreground"
                                        />
                                        <h4 className="font-semibold">
                                            {objective.title}
                                        </h4>
                                    </div>
                                    {objective.date && (
                                        <p className="text-sm text-muted-foreground mb-2">
                                            {objective.date}
                                        </p>
                                    )}
                                    {objective.tasks?.length > 0 && (
                                        <ul className="list-disc list-inside text-sm">
                                            {objective.tasks.map(
                                                (task, index) => (
                                                    <li key={index}>{task}</li>
                                                ),
                                            )}
                                        </ul>
                                    )}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="text-sm text-muted-foreground">
                            Nenhum objetivo neste ciclo.
                        </p>
                    )}
                </div>
            ) : (
                <p className="text-sm text-muted-foreground">
                    Nenhum ciclo atual encontrado para {currentYear}.
                </p>
            )}

            {/* TODOS OS CICLOS */}
            <h2 className="text-2xl font-bold my-4">Todos os Ciclos</h2>
            {cyclesStructure.length === 0 ? (
                <p>Nenhum ciclo encontrado.</p>
            ) : (
                <div className="space-y-2">
                    {cyclesStructure.map((yearItem) => (
                        <div
                            key={yearItem._id}
                            className="border rounded-lg p-2"
                        >
                            <button
                                className="flex items-center w-full text-left p-2 hover:bg-accent/50 rounded"
                                onClick={() => toggleYear(yearItem._id)}
                            >
                                {expandedYears[yearItem._id] ? (
                                    <ChevronDown size={24} />
                                ) : (
                                    <ChevronRight size={24} />
                                )}
                                {/* Ícone de pasta maior */}
                                <Folder size={24} className="mr-2" />
                                <span className="font-semibold">
                                    {yearItem._id}
                                </span>
                            </button>
                            {expandedYears[yearItem._id] && (
                                <div className="ml-6 space-y-2">
                                    {yearItem.cycles.map((cycle) => (
                                        <div
                                            key={cycle.cycleId}
                                            className="border-l pl-2"
                                        >
                                            <button
                                                className="flex items-center w-full text-left p-2 hover:bg-accent/50 rounded"
                                                onClick={() =>
                                                    toggleCycle(cycle.cycleId)
                                                }
                                            >
                                                {expandedCycles[
                                                    cycle.cycleId
                                                ] ? (
                                                    <ChevronDown size={24} />
                                                ) : (
                                                    <ChevronRight size={24} />
                                                )}
                                                <Folder
                                                    size={24}
                                                    className="mr-2"
                                                />
                                                <span>{cycle.cycle}</span>
                                            </button>
                                            {expandedCycles[cycle.cycleId] && (
                                                <div className="ml-6 space-y-1">
                                                    {cycleObjectives[
                                                        cycle.cycleId
                                                    ] ? (
                                                        cycleObjectives[
                                                            cycle.cycleId
                                                        ].length > 0 ? (
                                                            cycleObjectives[
                                                                cycle.cycleId
                                                            ].map(
                                                                (objective) => (
                                                                    <button
                                                                        key={
                                                                            objective._id
                                                                        }
                                                                        className="flex items-center w-full text-left p-1 hover:bg-accent/50 rounded"
                                                                        onClick={() =>
                                                                            onEditObjective(
                                                                                objective,
                                                                            )
                                                                        }
                                                                    >
                                                                        <File
                                                                            size={
                                                                                24
                                                                            }
                                                                            className="mr-2"
                                                                        />
                                                                        <span className="text-sm">
                                                                            {
                                                                                objective.title
                                                                            }
                                                                        </span>
                                                                    </button>
                                                                ),
                                                            )
                                                        ) : (
                                                            <p className="text-sm text-muted-foreground">
                                                                Nenhum objetivo
                                                                neste ciclo.
                                                            </p>
                                                        )
                                                    ) : (
                                                        <p className="text-sm text-muted-foreground">
                                                            Carregando
                                                            objetivos...
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CycleView;
