import React from "react";
import { Edit, Trash2 } from "lucide-react";

const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + "...";
};

const NotePreview = ({ note, onEdit, onDelete, isSelected, onSelect }) => {
    return (
        <div
            className={`bg-secondary text-secondary-foreground rounded-lg p-3 cursor-pointer hover:bg-accent transition-all flex flex-col h-full relative ${
                isSelected ? "border-2 border-primary" : ""
            }`}
            onClick={() => onSelect(note)} // Ao clicar, seleciona ou visualiza a nota
        >
            <div className="flex justify-between items-start mb-2">
                <h3 className="text-base font-semibold truncate">
                    {note.title}
                </h3>
                <div className="flex items-center space-x-1.5">
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            onEdit(e);
                        }}
                        className="p-1 bg-secondary hover:bg-secondary/80 text-secondary-foreground rounded"
                        title="Editar nota"
                    >
                        <Edit size={14} />
                    </button>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            onDelete(e);
                        }}
                        className="p-1 bg-destructive hover:bg-destructive/80 text-destructive-foreground rounded"
                        title="Excluir nota"
                    >
                        <Trash2 size={14} />
                    </button>
                </div>
            </div>
            <p className="text-sm text-muted-foreground mb-2 line-clamp-4">
                {truncateText(note.content, 150)}
            </p>
            {note.tags && (
                <div className="flex flex-wrap gap-1 mt-auto">
                    {note.tags.slice(0, 2).map((tag) => (
                        <span
                            key={tag}
                            className="px-2 py-0.5 bg-secondary text-secondary-foreground rounded-full text-xs"
                        >
                            {truncateText(tag, 10)}
                        </span>
                    ))}
                    {note.tags.length > 2 && (
                        <span className="text-xs text-muted-foreground">
                            +{note.tags.length - 2}
                        </span>
                    )}
                </div>
            )}
        </div>
    );
};

export default NotePreview;
