import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useSwipeable } from "react-swipeable";
import {
    ChevronDown,
    ChevronRight,
    Settings,
    HelpCircle,
    LogOut,
    Target,
    FileText,
    Book,
    CheckSquare,
    Folder,
    File,
    Search,
    Pin,
    Calculator,
    Calendar,
    Map,
    Menu,
    X,
} from "lucide-react";

/**
 * Componente para permitir swipe (arraste) e fixar/desafixar itens (via botão).
 */
const SwipeableItem = ({ children, onPin, isPinned }) => {
    const [offset, setOffset] = useState(0);
    const [isRevealed, setIsRevealed] = useState(false);

    const handlers = useSwipeable({
        onSwiping: (eventData) => {
            if (eventData.deltaX < 0) {
                setOffset(Math.max(-100, eventData.deltaX));
            } else if (isRevealed) {
                setOffset(Math.min(0, -100 + eventData.deltaX));
            }
        },
        onSwipedLeft: () => {
            setIsRevealed(true);
            setOffset(-100);
        },
        onSwipedRight: () => {
            setIsRevealed(false);
            setOffset(0);
        },
        trackMouse: true,
        trackTouch: true,
    });

    return (
        <div className="relative overflow-hidden" {...handlers}>
            <div
                className="transition-transform duration-200 ease-out"
                style={{ transform: `translateX(${offset}px)` }}
            >
                {children}
            </div>
            <button
                className={`absolute right-0 top-0 bottom-0 w-[100px] ${
                    isPinned ? "bg-blue-700" : "bg-blue-500"
                } text-white flex items-center justify-center transition-transform duration-200 ease-out`}
                style={{ transform: `translateX(${100 + offset}px)` }}
                onClick={() => {
                    onPin();
                    setIsRevealed(false);
                    setOffset(0);
                }}
            >
                <Pin size={20} />
            </button>
        </div>
    );
};

/**
 * Dropdown com lista de itens (Objetivos, Notas, Journals) que podem ser "pinnados".
 */
const DropdownMenu = ({
    title,
    items,
    onItemClick,
    activeItem,
    icon: Icon,
    searchTerm,
    onPinItem,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const sortedAndFilteredItems = useMemo(() => {
        let filteredItems = items;
        if (searchTerm) {
            filteredItems = items.filter((item) =>
                item.title.toLowerCase().includes(searchTerm.toLowerCase()),
            );
        }
        return filteredItems.sort((a, b) => {
            if (a.isPinned === b.isPinned) {
                return a.title.localeCompare(b.title);
            }
            return a.isPinned ? -1 : 1;
        });
    }, [items, searchTerm]);

    return (
        <div className="mb-2">
            <button
                className="flex items-center w-full p-2 text-left hover:bg-accent/50 rounded transition-colors"
                onClick={() => setIsOpen(!isOpen)}
            >
                {isOpen ? (
                    <ChevronDown size={28} className="mr-1" />
                ) : (
                    <ChevronRight size={28} className="mr-1" />
                )}
                <Icon size={28} className="mr-2" />
                <span className="text-lg">
                    {title} ({items.length})
                </span>
            </button>
            {isOpen && (
                <ul>
                    {sortedAndFilteredItems.map((item, index) => (
                        <li key={item._id || index}>
                            <SwipeableItem
                                onPin={() => onPinItem(item)}
                                isPinned={item.isPinned}
                            >
                                <button
                                    className={`w-full p-2 text-left rounded transition-colors flex items-center ${
                                        activeItem &&
                                        activeItem._id === item._id
                                            ? "bg-accent text-accent-foreground"
                                            : "hover:bg-accent/50"
                                    }`}
                                    onClick={() => onItemClick(item)}
                                >
                                    <File
                                        size={20}
                                        className="mr-2 flex-shrink-0"
                                    />
                                    <span className="truncate text-base">
                                        {item.title}
                                    </span>
                                    {item.isPinned && (
                                        <Pin
                                            size={16}
                                            className="ml-2 text-blue-500"
                                        />
                                    )}
                                </button>
                            </SwipeableItem>
                        </li>
                    ))}
                    {sortedAndFilteredItems.length === 0 && (
                        <li className="p-2 text-muted-foreground">
                            Nenhum resultado encontrado
                        </li>
                    )}
                </ul>
            )}
        </div>
    );
};

/**
 * Navigation principal, com menu lateral e botão sanduíche sempre visível.
 */
const Navigation = ({
    objectives,
    notes,
    journals,
    onItemClick,
    activeSection,
    activeItem,
    handleCardClick,
    handleLogout,
    isMenuOpen,
    toggleMenu,
    searchTerm,
    setSearchTerm,
    onPinItem: propOnPinItem,
}) => {
    /**
     * Este hook detecta quando a tela é grande ou pequena,
     * para eventualmente forçar o menu aberto se quiser (opcional).
     */
    const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth >= 1024); // >= "lg" no Tailwind
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Queremos permitir abrir/fechar o menu também em telas grandes.
    // Portanto, não forçamos "true" em isDesktop; apenas sabemos se é grande ou não.
    const finalIsMenuOpen = isMenuOpen; // se quiser forçar aberto em desktop, faria: isDesktop ? true : isMenuOpen

    const menuItems = [
        { section: "objectives", title: "Objetivos", icon: Target },
        { section: "notes", title: "Anotações", icon: FileText },
        { section: "journals", title: "Journaling", icon: Book },
        { section: "tasks", title: "Tarefas", icon: CheckSquare },
        { section: "cycleView", title: "Ciclos e Objetivos", icon: Calendar },
        {
            section: "cycleCalculator",
            title: "Calculadora de Ciclos",
            icon: Calculator,
        },
        { section: "magicMap", title: "Mapa de Vida", icon: Map },
    ];

    /**
     * Toggle pin: Chama a rota /toggle-pin e depois atualiza o estado global.
     */
    const togglePin = useCallback(
        async (itemType, itemId) => {
            try {
                const response = await fetch(
                    `https://organifyhub.com:5003/api/${itemType}/${itemId}/toggle-pin`,
                    {
                        method: "PUT",
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                            "Content-Type": "application/json",
                        },
                    },
                );
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                propOnPinItem(itemType, itemId, data.isPinned);
            } catch (error) {
                console.error("Erro ao fixar/desafixar o item:", error);
            }
        },
        [propOnPinItem],
    );

    return (
        <>
            {/* Botão do menu - sempre visível */}
            <button
                className="fixed top-4 left-4 z-40 p-2 rounded-md bg-secondary hover:bg-accent/50 transition-colors"
                onClick={toggleMenu}
                aria-label="Toggle menu"
            >
                {finalIsMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>

            {/* Sidebar "fixed" para poder abrir/fechar em qualquer tela */}
            <nav
                className={`
          fixed inset-y-0 left-0 transform
          ${finalIsMenuOpen ? "translate-x-0" : "-translate-x-full"}
          transition duration-200 ease-in-out
          z-30
          w-72 md:w-96
          bg-secondary p-6 flex-shrink-0 flex flex-col overflow-y-auto
        `}
            >
                <div className="flex items-center justify-center mb-4">
                    <div className="w-40 h-40 relative overflow-hidden">
                        <img
                            src="/logo.png"
                            alt="Organify Logo"
                            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full object-contain"
                        />
                    </div>
                </div>

                <div className="relative mb-4">
                    <input
                        type="search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Pesquisar nos arquivos..."
                        className="w-full pl-10 pr-4 py-2 rounded-md bg-accent text-accent-foreground placeholder-accent-foreground/50 focus:outline-none focus:ring-2 focus:ring-primary"
                    />
                    <Search
                        className="absolute left-3 top-1/2 transform -translate-y-1/2 text-accent-foreground/50"
                        size={20}
                    />
                </div>

                <ul className="flex flex-col space-y-2">
                    {menuItems.map(({ section, title, icon: Icon }) => (
                        <li key={section} className="w-full">
                            <button
                                className={`w-full text-left p-2 md:p-3 rounded transition-colors flex items-center ${
                                    activeSection === section
                                        ? "bg-accent text-accent-foreground"
                                        : "hover:bg-accent/50"
                                }`}
                                onClick={() => handleCardClick(section)}
                            >
                                <Icon size={28} className="mr-3" />
                                <span className="text-lg">{title}</span>
                            </button>
                        </li>
                    ))}

                    <li className="my-4 border-t border-accent"></li>

                    <li>
                        <DropdownMenu
                            title="Objetivos"
                            items={objectives}
                            onItemClick={(item) =>
                                onItemClick(item, "objectives")
                            }
                            activeItem={
                                activeSection === "objectives"
                                    ? activeItem
                                    : null
                            }
                            icon={Folder}
                            searchTerm={searchTerm}
                            onPinItem={(item) =>
                                togglePin("objectives", item._id)
                            }
                        />
                    </li>
                    <li>
                        <DropdownMenu
                            title="Notas"
                            items={notes}
                            onItemClick={(item) => onItemClick(item, "notes")}
                            activeItem={
                                activeSection === "notes" ? activeItem : null
                            }
                            icon={Folder}
                            searchTerm={searchTerm}
                            onPinItem={(item) => togglePin("notes", item._id)}
                        />
                    </li>
                    <li>
                        <DropdownMenu
                            title="Journaling"
                            items={journals}
                            onItemClick={(item) =>
                                onItemClick(item, "journals")
                            }
                            activeItem={
                                activeSection === "journals" ? activeItem : null
                            }
                            icon={Folder}
                            searchTerm={searchTerm}
                            onPinItem={(item) =>
                                togglePin("journals", item._id)
                            }
                        />
                    </li>
                </ul>

                <div className="mt-auto space-y-2">
                    <button className="flex items-center w-full p-3 rounded hover:bg-accent/50 transition-colors">
                        <Settings size={28} className="mr-3" />
                        <span className="text-lg">Configurações</span>
                    </button>
                    <button
                        className="flex items-center w-full p-3 rounded hover:bg-accent/50 transition-colors text-destructive"
                        onClick={handleLogout}
                    >
                        <LogOut size={28} className="mr-3" />
                        <span className="text-lg">Logout</span>
                    </button>
                    <button className="flex items-center w-full p-3 rounded hover:bg-accent/50 transition-colors">
                        <HelpCircle size={28} className="mr-3" />
                        <span className="text-lg">Ajuda</span>
                    </button>
                </div>
            </nav>

            {/* Escurece tela ao abrir o menu, se for mobile ou qualquer tela */}
            {finalIsMenuOpen && (
                <div
                    className="fixed inset-0 bg-black/50 z-20 lg:hidden"
                    onClick={toggleMenu}
                />
            )}
        </>
    );
};

export default Navigation;
